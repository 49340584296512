<template>
    <div class="wraper" v-if="detail">
        <!-- <Header :isShow="isShow" />
        <div class="banner">
            <img src="@/assets/images/a123.jpg" alt="" />
        </div> -->
        <div class="content max_big_box" ref="header" >
             <div class="title max_small_box">
                <p >广东省东莞市质量监督检测中心收费目录清单</p>
            </div>
            <div class="line"></div>
            <div class="detail max_small_box"  v-html="detail.intro">
            </div>
            <a-table class="max_small_box" style="margin-top: 50px" :columns="columns" :data-source="data" :pagination="false" rowKey="id" :scroll="{ x: 600, y: 300 }">
                <a >{{ text }}</a>
            </a-table>
            <div class="pagination max_small_box">
                <a-pagination :default-current="page" :total="last_page" @change="handleChangePage" />
            </div>
            <!-- <div class="line"></div> -->
        </div>
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {

    //详情
    const detail = ref()

    //页数
    const page = ref(1)

    //总页数
    const last_page = ref()

    const columns = [
        {
            title: '检测产品/类别',
            dataIndex: 'type',
            key: 'type',
            scopedSlots: { customRender: 'type' },
        },
        {
            title: '检测项目名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '标准条款/检验细则编号',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '收费',
            dataIndex: 'charge',
            key: 'charge',
        },
    ];

    const data = ref()

     //获取详情
    const getDetail = async () => {
        let postData = {
            page_id: '/',
            name: '收费标准'
        }
        try{
            const result = await post('/index/get_statement', postData)
            // console.log('收费标准详情', result)
            if(result.code == 1){
                detail.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }

    const getList = async () => {
        let postData = {
            page: page.value
        }
        try{
            await post('/index/get_charge_standard', postData).then((res) => {
                // console.log('收费标准列表', res)
                data.value = res.data.data
                last_page.value = res.data.total
            })
            
        }catch(e) {
        
        }
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
    }

    watchEffect(() =>{
        getList()
    })

    return { detail, getDetail, getList, page, last_page, handleChangePage, columns, data }
}

export default {
    name: 'Toll',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail, getList, page, last_page, handleChangePage, columns, data } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        
        getDetail()  //获取详情

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, page, last_page, handleChangePage, columns, data }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.wraper{
    padding-bottom: 40px;
}
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
.content{
    margin-top: 50px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .title{
        text-align: center;
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
            @media screen and (max-width: 1200px){
                font-size: 20px;
            }
        }
    }
    .line{
        width: 100%;
        height: 1px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        width: 100%;
        overflow-x: auto;
        margin-top: 30px;
    }
}
.pagination{
    display: block;
    text-align: center;
    padding-top: 80px;
}
</style>