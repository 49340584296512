<template>
    <div class="wraper" v-if="detail">
        <Header :isShow="isShow" />
        <div class="banner">
            <img src="@/assets/images/a123.jpg" alt="" />
        </div>
        <div class="content max_big_box" ref="header" >
             <div class="title max_small_box">
                <p >广东省东莞市质量监督检测中心收费目录清单</p>
            </div>
            <div class="line"></div>
            <div class="detail max_small_box"  v-html="detail.intro">
            </div>

            <div class="max_small_box search-box" style="margin-top: 50px">
                <div class="small-search">
                    <!-- <p>检测项目名称：</p> -->
                    <div class="search">
                        <input type="text" name="" id="" v-model="searchName" placeholder="检测项目名称" @keyup.enter="onSubmit">
                    </div>
                </div>
                <!-- <div class="small-search" style="opacity: 0;">
                    <div class="search"></div>
                </div> -->
                <div class="small-search">
                    <!-- <p>检测产品/类别：</p> -->
                    <div class="search">
                        <input type="text" name="" id="" v-model="searchType" placeholder="检测产品/类别" @keyup.enter="onSubmit">
                    </div>
                </div>
                <div class="small-search">
                    <!-- <p>标准条款/检验细则编号：</p> -->
                    <div class="search">
                        <input type="text" name="" id="" v-model="searchNumber" placeholder="标准条款/检验细则编号" @keyup.enter="onSubmit">
                    </div>
                </div>
                <div class="small-search small-search-btn">
                    <p class="search-btn" @click="onSubmit()">搜索</p>
                </div>
            </div>
            <a-table class="max_small_box" :columns="columns" :data-source="data" :pagination="false" rowKey="id" :scroll="{ x: 600, y: 300 }">
                <a >{{ text }}</a>
            </a-table>
            <div class="pagination max_small_box">
                <a-pagination :current="page" :total="last_page" @change="handleChangePage" />
            </div>
            <!-- <div class="line"></div> -->
        </div>
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {

    //详情
    const detail = ref()

    //页数
    const page = ref(1)

    //总页数
    const last_page = ref()

    const columns = [
        {
            title: '检测项目名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '收费',
            dataIndex: 'charge',
            key: 'charge',
        },
        {
            title: '检测产品/类别',
            dataIndex: 'type',
            key: 'type',
            scopedSlots: { customRender: 'type' },
        },
        {
            title: '标准条款/检验细则编号',
            dataIndex: 'number',
            key: 'number',
        },
    ];

    const data = ref()
    const searchType = ref('')  //搜索框的值
    const searchName = ref('')  //搜索框的值
    const searchNumber = ref('')  //搜索框的值

     //获取详情
    const getDetail = async () => {
        let postData = {
            page_id: '/',
            name: '收费标准'
        }
        try{
            const result = await post('/index/get_statement', postData)
            // console.log('收费标准详情', result)
            if(result.code == 1){
                detail.value = result.data
            }else{
                // console.log('请求失败')
            }
            getList()
        }catch(e) {
        
        }
    }

    const getList = async () => {
        let postData = {
            page: page.value
        }
        if(searchType.value!=''){
            postData.type = searchType.value
        }
        if(searchName.value!=''){
            postData.name = searchName.value
        }
        if(searchNumber.value!=''){
            postData.number = searchNumber.value
        }
        try{
            await post('/index/get_charge_standard', postData).then((res) => {
                // console.log('收费标准列表', res)
                data.value = res.data.data
                last_page.value = res.data.total
            })
            
        }catch(e) {
        
        }
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
        getList()
    }

    watchEffect(() =>{
    })

    return { detail, getDetail, getList, page, last_page, handleChangePage, columns, data, searchType, searchName, searchNumber }
}

export default {
    name: 'Toll',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail, getList, page, last_page, handleChangePage, columns, data, searchType, searchName, searchNumber } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        //回车搜索
        const onSubmit = () => {
            page.value = 1
            getList()
        }

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        
        getDetail()  //获取详情

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, page, last_page, handleChangePage, columns, data, searchType, searchName, searchNumber, onSubmit }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
.content{
    margin-top: 50px;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .title{
        text-align: center;
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
            @media screen and (max-width: 1200px){
                font-size: 20px;
            }
        }
    }
    .line{
        width: 100%;
        height: 1px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        width: 100%;
        overflow-x: auto;
        margin-top: 30px;
    }
}
.pagination{
    display: block;
    text-align: center;
    padding-top: 80px;
}
.search-box{
    display: flex;
    
    .small-search{
        width: 25%;
        padding-right: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }
    .small-search-btn{
        width: 10%;
    }
    .search{
        /* width: 350px; */
        width: 100%;
        height: 50px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #BBBBBB;
        input{
            /* width: calc(100% - 300px); */
            width: 100%;
            display: inline-block;
            border: none;
            outline: none;
            font-size: 14px;
            line-height: inherit;
            padding-left: 12px;
        }
    }
    .search-btn{
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        background-color: #35519A;
        color: #fff;
        cursor: pointer;
    }
}
</style>